import react, { useState, useEffect } from "react";
import "./vita.css";
import img from "../imgs/desktop/vey-gestalten-mit-farbe-vita-eckig.png";
import imgm from "../imgs/mobile/vey-gestalten-mit-farbe-vita-mobil.png";
import axios from "axios";

const Vita = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState("");
  const [text, setText] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/vitas?populate=*"
      )
      .then((res) => {
        // console.log(res.data.data[0].attributes.text);
        // console.log(res.data.data[0].attributes.title);
        setText(res.data.data[0].attributes.text);
        setTitle(res.data.data[0].attributes.title);
        setData(res.data.data[0].attributes);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section class="vita">
      <h2 className="vitah2"> {title}</h2>
      <img
        className="vitaImg"
        src={`https://cmsstrapi-932913427151.herokuapp.com${
          isMobile
            ? data?.bild_Mobile.data[0].attributes?.url
            : data?.bild_Desktop.data[0].attributes?.url
        }`}
      />

      {text?.map((e) => {
        return (
          <p className="vitap">
            {e.children[0].text}
            <br />
          </p>
        );
      })}
      {/* <p className="vitap">
        Einem ganzen Haus, einer Fassade oder auch nur einem Zimmer ein neues
        Gesicht zu geben ist unsere täg- liche Freude und Inspiration. Dabei ist
        das Zusammenspiel von Materialien, Strukturen und Farben von Be- deutung
        und erfordert neben Kreativität und einer soliden Ausbildung auch
        Erfahrung. Seit 2018 bin ich mit meinem Team im Großraum Köln - Bonn -
        Aachen tätig. Durch vertrauensvolle konstruktive Besprechungen mit
        unseren Auftraggebern werden Wunschvorstellun- gen mit fachlich
        fundierten Kenntnissen und Visionen perfektioniert und führen zu einem
        ausgezeichneten Ergebnis. Die Zufriedenheit unserer Auftraggeber steht
        bei uns im Fokus.
      </p>
      <br />
      <p className="vitap">Wir freuen uns auf Sie und Ihr neues Projekt.</p>
      <p className="vitap">Ihr Sebastian Vey</p> */}
    </section>
  );
};

export { Vita };

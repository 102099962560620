import "./jobs.css";
import axios from "axios";

import img1 from "../imgs/desktop/vey-gestalten-mit-farbe-aktuellesmuster1-eckig.png";
import img2 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-jobs-eckig.png";
import img1m from "../imgs/mobile/vey-gestalten-mit-farbe-aktuelles-mobil.png";
import img2m from "../imgs/mobile/vey-gestalten-mit-farbe-jobs-mobil.png";

import icon1 from "../imgs/desktop/icon1.png";
import icon2 from "../imgs/desktop/icon2.png";
import { useState, useEffect } from "react";

const Jobs1 = () => {
  const [jobs, setJobs] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isJobs, setIsJobs] = useState(false);
  const [jobText, setjobText] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/Jobs?populate=*"
      )
      .then((res) => {
        setjobText(res.data.data[0].attributes.Jobs_Beschreibung);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      console.log(window.location.pathname);
      if (window.location.pathname == "/aktuelles/jobs") {
        setIsJobs(false);
      } else if (window.location.pathname == "/jobs") {
        setIsJobs(true);
      }
    }
  }, [isMobile]);

  return (
    <section class="jobs">
      <div class="contentHead">
        <div class="jobsrightBox">Gestalten bei Vey</div>
      </div>
      <div class="imgesBox">
        <div className="img2Box">
          <img className="img2" src={isMobile ? img2m : img2} />
          <div className="img2BoxrightBox">
            <div class="jobsrightBox">Herzlichen Dank für Ihr Interesse!</div>
            <p></p>
            {jobText}
            <br /> <br />
            Vey Gestaltung, Sebastian Vey
            <br />
            OT Krekel
            <br />
            Rüther Straße 17
            <br />
            53925 Kall
            <br />
            E-Mail: Info@s-vey.de
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export { Jobs1 };

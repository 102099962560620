import heroBgGruenDesktop from "./imgs/desktop/vey-gestaltung-farbe-gruen.jpg";
import heroBgGelbDesktop from "./imgs/desktop/vey-gestaltung-farbe-gelb.jpg";
import heroBgGrauDesktop from "./imgs/desktop/vey-gestaltung-farbe-grau.jpg";

import heroBgGruenMobile from "./imgs/mobile/vey-gestalten-mit-farbe-gruen-mobil.png";
import heroBgGelbMobile from "./imgs/mobile/vey-gestalten-mit-farbe-gelb-mobil.png";
import heroBgGrauMobile from "./imgs/mobile/vey-gestalten-mit-farbe-grau-mobil.png";

import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/header.js";

import { Home } from "./pages/home.js";
import { Vita } from "./pages/vita.js";
import { Jobs } from "./pages/jobs.js";
import { Jobs1 } from "./pages/jobs1.js";
import { Kontakt } from "./pages/kontakt.js";
import { Impressum } from "./pages/impressum.js";
import { Cookies } from "./pages/cookies.js";
import { AnimatePresence, motion } from "framer-motion";

const App = () => {
  const imagesDesktop = [
    heroBgGruenDesktop,
    heroBgGelbDesktop,
    heroBgGrauDesktop,
  ];
  const imagesMobile = [heroBgGruenMobile, heroBgGelbMobile, heroBgGrauMobile];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [x, setx] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setx(false);

      const timeout = setTimeout(() => {
        setx(true);
      }, 200);

      setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesDesktop.length);

      return () => clearTimeout(timeout);
    }, 3000);

    return () => clearInterval(interval);
  }, [imagesDesktop.length]);

  const currentImage = isMobile
    ? imagesMobile[currentIndex]
    : imagesDesktop[currentIndex];

  return (
    <div className="App">
      <BrowserRouter>
        <Header />

        <section className="hero">
          <img
            key={2}
            className="heroBg"
            src={isMobile ? imagesMobile[2] : imagesDesktop[2]}
            alt="Hero Image 3"
          />
          <img
            key={1}
            className="heroBg"
            src={isMobile ? imagesMobile[1] : imagesDesktop[1]}
            alt="Hero Image 2"
          />

          <img
            key={0}
            className="heroBg"
            src={isMobile ? imagesMobile[0] : imagesDesktop[0]}
            alt="Hero Image 1"
          />
        </section>

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/vita" element={<Vita />}></Route>
          <Route path="/aktuelles/jobs" element={<Jobs />}></Route>
          <Route path="/jobs" element={<Jobs1 />}></Route>
          <Route path="/kontakt" element={<Kontakt />}></Route>
          <Route path="/impressum" element={<Impressum />}></Route>
          <Route path="/datenschutz/cookies" element={<Cookies />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

import { useEffect, useState } from "react";
import "./impressum.css";
import axios from "axios";

const Impressum = () => {
  const [text, setText] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/Impressums?populate=*"
      )
      .then((res) => {
        console.log(res.data.data[0].attributes);
        setText(res.data.data[0].attributes);
      });
  }, []);

  return (
    <section class="impressum">
      <div class="impressumleftBox">
        {text?.text_left?.map((e) => {
          return e.children[0]?.bold ? (
            <span
              style={{
                textDecoration: e.children[0]?.underline ? "underline" : "none",
                cursor: e.children[0]?.underline ? "pointer" : "none",
              }}
              onClick={() => {
                if (e.children[0]?.underline) {
                  window.open("mailto:Info@s-vey.de", "_blank");
                }
              }}
            >
              {e.children[0].text} <br />
            </span>
          ) : (
            <div
              style={{
                textDecoration: e.children[0]?.underline ? "underline" : "none",
                cursor: e.children[0]?.underline ? "pointer" : "none",
              }}
              onClick={() => {
                if (e.children[0]?.underline) {
                  window.open("mailto:Info@s-vey.de", "_blank");
                }
              }}
            >
              {e.children[0].text} <br />
            </div>
          );
        })}
        {/* <span>Impressum</span>
        <br />
        <span>Angaben gemäß §5 TMG</span>
        <br />
        <br />
        Vey Gestaltung, Sebastian Vey
        <br />
        OT Krekel
        <br />
        Rüther Straße 17
        <br />
        53925 Kall
        <br />
        <br />
        <span>Kontakt</span> <br />
        Telefon: 0163 479 5771
        <br />
        <a href="mailto:Info@s-vey.de">E-Mail: Info@s-vey.de</a> <br />
        <a>www.s-vey.de</a>
        <br /> */}
      </div>

      <div class="impressumrightBox">
        {text?.text_right?.map((e) => {
          return e.children[0]?.bold ? (
            <span>
              {" "}
              {e.children[0].text} <br />
            </span>
          ) : (
            <div>
              {" "}
              {e.children[0].text} <br />
            </div>
          );
        })}

        {/* <span>Umsatzsteuer-ID</span> <br />
        Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuerge-
        <br />
        setz: DE hier die USt-IdNr. einsetzen
        <br />
        <br />
        <span>EU-Streitschlichtung</span>
        <br />
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbei-
        <br />
        legung (OS) bereit: https://ec.europa.eu/consumers/odr/.
        <br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
        <br />
        Verbraucherstreitbeilegung/Universalschlichtungsstelle
        <br />
        <br />
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor
        <br />
        einer Verbraucherschlichtungsstelle teilzunehmen.
        <br />
        <br />
        <span>Redaktionell verantwortlich</span> <br />
        Sebastian Vey
        <br />
        <br />
        <span>Konzeption und Gestaltung</span> <br />
        A-design Elisabeth Aschke, Mechernich
        <br />
        <br />© Sebastian Vey 2023 */}
      </div>
    </section>
  );
};

export { Impressum };

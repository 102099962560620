import "./kontakt.css";
import img from "../imgs/desktop/vey-gestalten-mit-farbe-kontakt-eckig.png";
import imgm from "../imgs/mobile/vey-gestalten-mit-farbe-kontakt-mobil.png";
import axios from "axios";

import { useState, useEffect } from "react";

const Kontakt = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [text, setText] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/kontakts?populate=*"
      )
      .then((res) => {

        console.log(res);
        

        setText(res.data.data[0].attributes.text);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section class="kontakt">
      <img className="kontaktImg" src={isMobile ? imgm : img} />
      <div class="textBox">

        {text?.map((e) => {
          return (
            <div
              class={e.children[0]?.bold ? "kontakth2" : "kontakttext1"}
              style={
                e.children[0]?.underline
                  ? { textDecoration: "underline", cursor: "pointer" }
                  : {}
              }
              onClick={() => {
                if (e.children[0]?.underline) {
                  window.open("mailto:Info@s-vey.de", "_blank");
                }
              }}
            >
              {e.children[0].text}
              <br />
            </div>
          );
        })}

        {/* <h2 className="kontakth2">Haben Sie Fragen oder Wünsche?</h2>
        <p class="kontakttext1">
          Wir sind für Sie da und freuen uns auf Ihre Nachricht!
          <br />
          Schreiben Sie uns einfach eine E-Mail, wir werden uns gerne mit Ihnen
          <br />
          in Verbindung setzen - oder rufen Sie mich einfach an.
          <br />
        </p>
        <p class="kontakttext2">
          Vey Gestaltung
          <br />
          Sebastian Vey
          <br />
          Telefon: 0163 479 5771
          <br />
          <a href="mailto:Info@s-vey.de">E-Mail: Info@s-vey.de</a>
          <br />
          <a>www.s-vey.de</a> <br />
        </p> */}
      </div>
    </section>
  );
};

export { Kontakt };

// Head.js
import React, { useEffect, useState } from "react";
import "../Head.css";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const Header = () => {
  const [navMobile, setNavMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const head = document.querySelector(".head");
    const handleScroll = () => {
      if (window.scrollY > 100) {
        head.style.backgroundColor = "#ffffffea";
      } else {
        head.style.backgroundColor = "#ffffff7a";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getActiveStyle = ({ isActive }) => ({
    textDecoration: isActive ? "underline" : "none",
    fontWeight: isActive ? "400" : "normal",
    color: isActive ? "black" : "inherit",
  });

  window.addEventListener("click", () => {
    setNavMobile(false);
  });

  return (
    <div
      className="head"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="nav">
        <NavLink exact to="/" style={getActiveStyle}>
          Start
        </NavLink>
        <NavLink to="/vita" style={getActiveStyle}>
          Vita
        </NavLink>
        <NavLink to="/aktuelles/jobs" style={getActiveStyle}>
          Aktuelles /Jobs
        </NavLink>
        <NavLink to="/kontakt" style={getActiveStyle}>
          Kontakt
        </NavLink>
        <NavLink to="/impressum" style={getActiveStyle}>
          Impressum
        </NavLink>
        <NavLink to="/datenschutz/cookies" style={getActiveStyle}>
          Datenschutz/Cookies
        </NavLink>
      </div>
      {/* <div className="icons">
                <i className="fa-brands fa-instagram"></i>
                <i className="fa-brands fa-facebook-f"></i>
                <i className="fa-brands fa-whatsapp"></i>
            </div> */}
      <div
        className="bars"
        onClick={(e) => {
          e.stopPropagation();
          setNavMobile(!navMobile);
        }}
      >
        {!navMobile ? (
          <>
            <div></div>
            <div></div>
            <div></div>
          </>
        ) : (
          <i className="fa-solid fa-x"></i>
        )}
      </div>

      <AnimatePresence>
        {navMobile && (
          <motion.div
            className="navMobile"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            exit={{ x: 500 }}
          >
            <NavLink
              exact
              to="/"
              style={getActiveStyle}
              onClick={() => setNavMobile(false)}
            >
              Start
            </NavLink>
            <NavLink
              to="/vita"
              style={getActiveStyle}
              onClick={() => setNavMobile(false)}
            >
              Vita
            </NavLink>
            <NavLink
              to={"/aktuelles/jobs"}
              style={getActiveStyle}
              onClick={() => setNavMobile(false)}
            >
              Aktuelles {!isMobile && "/ Jobs"}{" "}
            </NavLink>
            {isMobile && (
              <NavLink
                to={"/jobs"}
                style={getActiveStyle}
                onClick={() => setNavMobile(false)}
              >
                Jobs
              </NavLink>
            )}
            <NavLink
              to="/kontakt"
              style={getActiveStyle}
              onClick={() => setNavMobile(false)}
            >
              Kontakt
            </NavLink>
            <NavLink
              to="/impressum"
              style={getActiveStyle}
              onClick={() => setNavMobile(false)}
            >
              Impressum
            </NavLink>
            <NavLink
              to="/datenschutz/cookies"
              style={getActiveStyle}
              onClick={() => setNavMobile(false)}
            >
              Datenschutz / Cookies
            </NavLink>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export { Header };

import { useEffect, useState } from "react";
import "../App.css";
import { motion, AnimatePresence, delay } from "framer-motion";
import axios from "axios";

import img1m from "../imgs/mobile/vey-gestalten-mit-farbe-leistungen-mobil.png";
import img2m from "../imgs/mobile/vey-gestalten-mit-farbe-leistungen-spachteltechnik-mobil.png";
import img3m from "../imgs/mobile/vey-gestalten-mit-farbe-leistungen-boeden-mobil.png";
import img4m from "../imgs/mobile/vey-gestalten-mit-farbe-leistungen-trockenbau-mobil.png";
import img5m from "../imgs/mobile/vey-gestalten-mit-farbe-leistungen-sanierungen-mobil.png";
import img6m from "../imgs/mobile/vey-gestalten-mit-farbe-leistungen-fassaden-mobil.png";

import imgBox1 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-eckig.png";
import imgBox2 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-spachteltechnik-eckig.png";
import imgBox3 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-boeden-eckig.png";
import imgBox4 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-trockenbau-eckig.png";
import imgBox5 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-sanierungen-eckig.png";
import imgBox6 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-fassaden-eckig.png";

const Home = () => {
  const [Showparagraph, setShowparagraph] = useState(false);
  const [Showparagraph1, setShowparagraph1] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [data, setData] = useState([]);
  const [text, setText] = useState([]);

  const [num, setNum] = useState(1);

  useEffect(() => {
    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/homes?populate=*"
      )
      .then((res) => {
        let data = [];

        res.data.data.forEach((e) => {
          data.push({
            title: e.attributes.title,
            bild_Desktop: e.attributes.bild_Desktop.data[0].attributes.url,
            bild_Mobile: e.attributes.bild_Mobile.data[0].attributes.url,
          });
        });

        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/home-texts?populate=*"
      )
      .then((res) => {
        setText(res.data.data[0].attributes.text);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const close = () => {
    const elements = document.getElementsByClassName("imgBox");
    const elementsArray = Array.from(elements);

    elementsArray.forEach((element) => {
      element.style.border = "none";
    });
  };

  const paragraph = (num) => {
    close();
    if (!isMobile) {
      const element = document.getElementById(`${num}`);
      element.style.border = "2px solid black";
    }

    setShowparagraph1(false);
    setShowparagraph(true);
    setNum(num);

    setTimeout(() => {
      if (isMobile) {
        // const element = document.getElementById(`mobile${num}`);
        // const  rect = element.getBoundingClientRect();
        // const  elementTop = rect.top - 150 ;
        // window.scrollTo({
        //     top: elementTop,
        //     behavior: 'smooth'
        // });
      } else {
        const link = document.createElement("a");
        link.href = "#text";
        link.click();
      }
    }, 100);
  };

  const paragraph1 = (num) => {
    close();
    if (!isMobile) {
      const element = document.getElementById(`${num}`);
      element.style.border = "2px solid black";
    }

    setShowparagraph(false);
    setShowparagraph1(true);
    setNum(num);

    setTimeout(() => {
      if (isMobile) {
        // const element = document.getElementById(`mobile${num}`);
        // const  rect = element.getBoundingClientRect();
        // const  elementTop = rect.top - 150 ;
        // window.scrollTo({
        //     top: elementTop,
        //     behavior: 'smooth'
        // });
      } else {
        const link = document.createElement("a");
        link.href = "#text";
        link.click();
      }
    }, 100);
  };

  return (
    <section className="min">
      <section className="Category">
        <div className="CategoryBox">
          {text.map((e) => {
            return (
              <p
                className={`kontakttext1 ${
                  e.children[0]?.bold ? "vitah2" : ""
                } `}
              >
                {" "}
                {e.children[0].text}{" "}
              </p>
            );
          })}

          {/* <h2 className="vitah2">Die veynste Art mit Farbe zu gestalten</h2>
          <p className="kontakttext1">
            Schöne Räume zum Wohlfühlen; Stilsicherheit in Farbe, Technik und
            Material, das ist unser Anspruch.
            <br /> Herzlich willkommen bei Vey Gestaltung & mehr...
          </p> */}
          <div className="imgsBox">
            <div id="1" className="imgBox">
      
              <img
                src={`https://cmsstrapi-932913427151.herokuapp.com${
                  isMobile ? data[0]?.bild_Mobile : data[0]?.bild_Desktop
                }`}
                alt="Gestalten mit Farbe"
              />
              <div className="title"> {data[0]?.title}</div>
              <i className="fa-solid fa-play" onClick={() => paragraph(1)}></i>
            </div>

            {isMobile ? (
              <div className="paragraph">
                <div className="icon">
                  <i
                    className="fa-solid fa-play"
                    onClick={() => {
                      if (Showparagraph && num == 1) {
                        close();
                        setShowparagraph(false);
                      } else if (Showparagraph) {
                        close();
                        setShowparagraph(false);
                        paragraph(1);
                      } else {
                        paragraph(1);
                      }
                    }}
                  ></i>
                </div>
                <div className="text" id="text">
                  <span>Gestalten mit Farbe</span>
                  <AnimatePresence>
                    {Showparagraph && num == 1 ? (
                      <motion.p
                        id="mobile1"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                          height: "auto",
                          opacity: 1,
                        }}
                        exit={{ height: 1, opacity: 0 }}
                        transition={{ duration: 0.5, type: "tween" }}
                      >
                        Farben verändern die Ausstrahlung eines Raumes komplett.
                        Gemütlichkeit im Wohnzimmer, Stille im Schlafzimmer,
                        Fröhlichkeit im Kinderzimmer, Konzentration am
                        Arbeitsplatz und die Küche als Mittelpunkt der Familie.
                        Allein die angemessene Farbwahl kann die Erwartungen an
                        unterschiedliche Lebensräume erfüllen. Wir arbeiten mit
                        qualitativ hochwertigen Produkten und verzichten bewusst
                        auf Lösemittel und Schadstoffe. Wir achten auf
                        Nachhaltigkeit. Ihr Zuhause wird zur Wohlfühloase.
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}

            <div id="2" className="imgBox">
              <img
                src={`https://cmsstrapi-932913427151.herokuapp.com${
                  isMobile ? data[1]?.bild_Mobile : data[1]?.bild_Desktop
                }`}
                alt="Spachteltechnik"
              />
              <div className="title1">{data[1]?.title}</div>
              <i className="fa-solid fa-play" onClick={() => paragraph(2)}></i>
            </div>

            {isMobile ? (
              <div
                className="paragraph"
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: "145px",
                  opacity: 1,
                }}
                exit={{ height: 1, opacity: 0 }}
                transition={{ duration: 0.5, type: "tween" }}
              >
                <div className="icon">
                  <i
                    className="fa-solid fa-play"
                    onClick={() => {
                      if (Showparagraph && num == 2) {
                        close();
                        setShowparagraph(false);
                      } else if (Showparagraph) {
                        close();
                        setShowparagraph(false);
                        paragraph(2);
                      } else {
                        paragraph(2);
                      }
                    }}
                  ></i>
                </div>
                <div className="text" id="text">
                  <span>Spachteltechnik</span>
                  <AnimatePresence>
                    {Showparagraph && num == 2 ? (
                      <motion.p
                        id="mobile2"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                          height: "auto",
                          opacity: 1,
                        }}
                        exit={{ height: 1, opacity: 0 }}
                        transition={{ duration: 0.5, type: "tween" }}
                      >
                        Gestaltungen sind mehr als Farbe. Fortschrittliche
                        Verarbeitungstechniken garan- tieren ein optimiertes
                        Preis-Leistungsverhältnis. Ob gewischt, gespachtelt,
                        gewickelt, marmoriert, oder getupft wird, wir
                        beherrschen dekorative Innenputztechniken ebenso wie
                        kreative Maltechni- ken. Auch schöne Tapeten kommen
                        gerne zu Einsatz. Wir verwenden nur Werkstoffe, die
                        Umwelt- und Emissionsstandards erfüllen. Ein
                        professionelles Ergebnis ist garantiert.{" "}
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}

            <div id="3" className="imgBox">
              <img
                src={`https://cmsstrapi-932913427151.herokuapp.com${
                  isMobile ? data[2]?.bild_Mobile : data[2]?.bild_Desktop
                }`}
                alt="Böden"
              />
              <div className="title1">{data[2]?.title}</div>
              <i className="fa-solid fa-play" onClick={() => paragraph(3)}></i>
            </div>

            {isMobile ? (
              <div
                className="paragraph"
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: "145px",
                  opacity: 1,
                }}
                exit={{ height: 1, opacity: 0 }}
                transition={{ duration: 0.5, type: "tween" }}
              >
                <div className="icon">
                  <i
                    className="fa-solid fa-play"
                    onClick={() => {
                      if (Showparagraph && num == 3) {
                        close();
                        setShowparagraph(false);
                      } else if (Showparagraph) {
                        close();
                        setShowparagraph(false);
                        paragraph(3);
                      } else {
                        paragraph(3);
                      }
                    }}
                  ></i>
                </div>
                <div className="text" id="text">
                  <span>Böden</span>
                  <AnimatePresence>
                    {Showparagraph && num == 3 ? (
                      <motion.p
                        id="mobile3"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                          height: "auto",
                          opacity: 1,
                        }}
                        exit={{ height: 1, opacity: 0 }}
                        transition={{ duration: 0.5, type: "tween" }}
                      >
                        Die Auswahl eines schönen Bodens ist ein wichtiger
                        Bestandteil zum Gesamtkonzept einer harmonischen
                        Ausstattung. Soll es z.B. ein Parkettboden, ein
                        Laminatboden oder ein Boden aus Fein- steinzeugfliesen
                        werden? Wir beraten Sie sehr gerne und ausführlich bei
                        Ihnen vor Ort. Eine qualitativ hochwertige, fach- und
                        sachgerechte Arbeit ist für uns eine
                        Selbstverständlichkeit, daher bieten wir Ihnen die
                        Verlegung in Kooperation mit einem Partnerunternehmen
                        an.
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}

            <AnimatePresence>
              {Showparagraph && !isMobile ? (
                <motion.div
                  className="paragraph"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{
                    height: "145px",
                    opacity: 1,
                  }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.5, type: "tween" }}
                >
                  <div className="icon">
                    <i
                      className="fa-solid fa-play"
                      onClick={() => {
                        close();
                        setShowparagraph(false);
                      }}
                    ></i>
                  </div>
                  <div className="text" id="text">
                    <AnimatePresence>
                      {num == 1 ? (
                        <motion.p
                          initial={{ height: 0, opacity: 0 }}
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3, type: "tween" }}
                        >
                          <span>Gestalten mit Farbe</span> Farben verändern die
                          Ausstrahlung eines Raumes komplett. Gemütlichkeit im
                          Wohnzimmer, Stille im Schlafzimmer, Fröhlichkeit im
                          Kinderzimmer, Konzentration am Arbeitsplatz und die
                          Küche als Mittelpunkt der Familie. Allein die
                          angemessene Farbwahl kann die Erwartungen an
                          unterschiedliche Lebensräume erfüllen. Wir arbeiten
                          mit qualitativ hochwertigen Produkten und verzichten
                          bewusst auf Lösemittel und Schadstoffe. Wir achten auf
                          Nachhaltigkeit. Ihr Zuhause wird zur Wohlfühloase.
                        </motion.p>
                      ) : num == 2 ? (
                        <motion.p
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3, type: "tween" }}
                        >
                          <span>Spachteltechnik</span> Gestaltungen sind mehr
                          als Farbe. Fortschrittliche Verarbeitungstechniken
                          garan- tieren ein optimiertes
                          Preis-Leistungsverhältnis. Ob gewischt, gespachtelt,
                          gewickelt, marmoriert, oder getupft wird, wir
                          beherrschen dekorative Innenputztechniken ebenso wie
                          kreative Maltechni- ken. Auch schöne Tapeten kommen
                          gerne zu Einsatz. Wir verwenden nur Werkstoffe, die
                          Umwelt- und Emissionsstandards erfüllen. Ein
                          professionelles Ergebnis ist garantiert.{" "}
                        </motion.p>
                      ) : num == 3 ? (
                        <motion.p
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3, type: "tween" }}
                        >
                          <span>Böden</span> Die Auswahl eines schönen Bodens
                          ist ein wichtiger Bestandteil zum Gesamtkonzept einer
                          harmonischen Ausstattung. Soll es z.B. ein
                          Parkettboden, ein Laminatboden oder ein Boden aus
                          Fein- steinzeugfliesen werden? Wir beraten Sie sehr
                          gerne und ausführlich bei Ihnen vor Ort. Eine
                          qualitativ hochwertige, fach- und sachgerechte Arbeit
                          ist für uns eine Selbstverständlichkeit, daher bieten
                          wir Ihnen die Verlegung in Kooperation mit einem
                          Partnerunternehmen an.
                        </motion.p>
                      ) : null}
                    </AnimatePresence>
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>

            <div id="4" className="imgBox">
              <img
                src={`https://cmsstrapi-932913427151.herokuapp.com${
                  isMobile ? data[3]?.bild_Mobile : data[3]?.bild_Desktop
                }`}
                alt="Trockenbau"
              />
              <div className="title1">{data[3]?.title}</div>
              <i className="fa-solid fa-play" onClick={() => paragraph1(4)}></i>
            </div>

            {isMobile ? (
              <div
                className="paragraph"
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: "145px",
                  opacity: 1,
                }}
                exit={{ height: 1, opacity: 0 }}
                transition={{ duration: 0.5, type: "tween" }}
              >
                <div className="icon">
                  <i
                    className="fa-solid fa-play"
                    onClick={() => {
                      if (Showparagraph1 && num == 4) {
                        close();
                        setShowparagraph1(false);
                      } else if (Showparagraph1) {
                        close();
                        setShowparagraph1(false);
                        paragraph1(4);
                      } else {
                        paragraph1(4);
                      }
                    }}
                  ></i>
                </div>
                <div className="text" id="text">
                  <span>Trockenbau</span>
                  <AnimatePresence>
                    {Showparagraph1 && num == 4 ? (
                      <motion.p
                        id="mobile4"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                          height: "auto",
                          opacity: 1,
                        }}
                        exit={{ height: 1, opacity: 0 }}
                        transition={{ duration: 0.5, type: "tween" }}
                      >
                        Ein ganz neues Wohnraumgefühl kann mit der
                        Trockenbautechnik im Innenbereich erreicht werden. In
                        Leichbauweise errichtete Trennwände,
                        Installationsvorwände, Abhängedecken oder z.B. auch
                        Dachausbauten bieten vielfältige Möglichkeiten für Ihren
                        Wohn- und Lebensbereich.
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}

            <div id="5" className="imgBox">
              <img
                src={`https://cmsstrapi-932913427151.herokuapp.com${
                  isMobile ? data[4]?.bild_Mobile : data[4]?.bild_Desktop
                }`}
                alt="Sanierungen"
              />
              <div className="title1">{data[4]?.title}</div>
              <i className="fa-solid fa-play" onClick={() => paragraph1(5)}></i>
            </div>

            {isMobile ? (
              <div
                className="paragraph"
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: "145px",
                  opacity: 1,
                }}
                exit={{ height: 1, opacity: 0 }}
                transition={{ duration: 0.5, type: "tween" }}
              >
                <div className="icon">
                  <i
                    className="fa-solid fa-play"
                    onClick={() => {
                      if (Showparagraph1 && num == 5) {
                        close();
                        setShowparagraph1(false);
                      } else if (Showparagraph1) {
                        close();
                        setShowparagraph1(false);
                        paragraph1(5);
                      } else {
                        paragraph1(5);
                      }
                    }}
                  ></i>
                </div>
                <div className="text" id="text">
                  <span>Sanierungen</span>
                  <AnimatePresence>
                    {Showparagraph1 && num == 5 ? (
                      <motion.p
                        id="mobile5"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                          height: "auto",
                          opacity: 1,
                        }}
                        exit={{ height: 1, opacity: 0 }}
                        transition={{ duration: 0.5, type: "tween" }}
                      >
                        Sie planen die Sanierung Ihrer Immobilie? Dann sind wir
                        für Sie ein zuverlässiger Part- ner. Wir bieten Ihnen
                        Komplettlösungen in den Bereichen renovieren,
                        restaurieren, modernisieren, sanieren, gestalten,
                        schützen, pflegen und instandsetzen an. Unsere
                        fortschrittlichen Verarbeitungs- techniken garantieren
                        ein optimiertes Preis-Leistungsverhältnis und ein
                        professionelles Ergebnis.
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}

            <div id="6" className="imgBox">
              <img
                src={`https://cmsstrapi-932913427151.herokuapp.com${
                  isMobile ? data[5]?.bild_Mobile : data[5]?.bild_Desktop
                }`}
                alt="Fassaden"
              />
              <div className="title">{data[5]?.title}</div>
              <i className="fa-solid fa-play" onClick={() => paragraph1(6)}></i>
            </div>

            {isMobile ? (
              <div
                className="paragraph"
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: "145px",
                  opacity: 1,
                }}
                exit={{ height: 1, opacity: 0 }}
                transition={{ duration: 0.5, type: "tween" }}
              >
                <div className="icon">
                  <i
                    className="fa-solid fa-play"
                    onClick={() => {
                      if (Showparagraph1 && num == 6) {
                        close();
                        setShowparagraph1(false);
                      } else if (Showparagraph1) {
                        close();
                        setShowparagraph1(false);
                        paragraph1(6);
                      } else {
                        paragraph1(6);
                      }
                    }}
                  ></i>
                </div>
                <div className="text" id="text">
                  <span>Fassaden</span>
                  <AnimatePresence>
                    {Showparagraph1 && num == 6 ? (
                      <motion.p
                        id="mobile6"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{
                          height: "auto",
                          opacity: 1,
                        }}
                        exit={{ height: 1, opacity: 0 }}
                        transition={{ duration: 0.5, type: "tween" }}
                      >
                        Fassaden Was passt zu der Architektur Ihres Hauses,
                        welche Farbe, Struktur oder Technik gefällt Ih- nen
                        persönlich? Was muss beachtet werden, damit sich Ihre
                        Fassade harmonisch in die Umgebung einfügt? Bei diesen
                        wichtigen Fragen unterstützen wir Sie mit unserer
                        erfahrenen Kompetenz. Es wird ein Ergebnis entstehen,
                        das sich sehen lassen kann.
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}

            <AnimatePresence>
              {Showparagraph1 && !isMobile ? (
                <motion.div
                  className="paragraph"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{
                    height: "145px",
                    opacity: 1,
                  }}
                  exit={{ height: 1, opacity: 0 }}
                  transition={{ duration: 0.5, type: "tween" }}
                >
                  <div className="icon">
                    <i
                      className="fa-solid fa-play"
                      onClick={() => {
                        close();
                        setShowparagraph1(false);
                      }}
                    ></i>
                  </div>
                  <div className="text" id="text">
                    <AnimatePresence>
                      {num == 4 ? (
                        <motion.p
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3, type: "tween" }}
                        >
                          <span>Trockenbau</span> Ein ganz neues Wohnraumgefühl
                          kann mit der Trockenbautechnik im Innenbereich
                          erreicht werden. In Leichbauweise errichtete
                          Trennwände, Installationsvorwände, Abhängedecken oder
                          z.B. auch Dachausbauten bieten vielfältige
                          Möglichkeiten für Ihren Wohn- und Lebensbereich.
                        </motion.p>
                      ) : num == 5 ? (
                        <motion.p
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3, type: "tween" }}
                        >
                          <span>Sanierungen</span> Sie planen die Sanierung
                          Ihrer Immobilie? Dann sind wir für Sie ein
                          zuverlässiger Part- ner. Wir bieten Ihnen
                          Komplettlösungen in den Bereichen renovieren,
                          restaurieren, modernisieren, sanieren, gestalten,
                          schützen, pflegen und instandsetzen an. Unsere
                          fortschrittlichen Verarbeitungs- techniken garantieren
                          ein optimiertes Preis-Leistungsverhältnis und ein
                          professionelles Ergebnis.
                        </motion.p>
                      ) : num == 6 ? (
                        <motion.p
                          animate={{
                            height: "auto",
                            opacity: 1,
                          }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3, type: "tween" }}
                        >
                          <span>Fassaden</span>Fassaden Was passt zu der
                          Architektur Ihres Hauses, welche Farbe, Struktur oder
                          Technik gefällt Ih- nen persönlich? Was muss beachtet
                          werden, damit sich Ihre Fassade harmonisch in die
                          Umgebung einfügt? Bei diesen wichtigen Fragen
                          unterstützen wir Sie mit unserer erfahrenen Kompetenz.
                          Es wird ein Ergebnis entstehen, das sich sehen lassen
                          kann.
                        </motion.p>
                      ) : null}
                    </AnimatePresence>
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </div>
        </div>
      </section>
    </section>
  );
};

export { Home };

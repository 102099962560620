import "./jobs.css";

import img1 from "../imgs/desktop/vey-gestalten-mit-farbe-aktuellesmuster1-eckig.png";
import img2 from "../imgs/desktop/vey-gestalten-mit-farbe-leistungen-jobs-eckig.png";
import img1m from "../imgs/mobile/vey-gestalten-mit-farbe-aktuelles-mobil.png";
import img2m from "../imgs/mobile/vey-gestalten-mit-farbe-jobs-mobil.png";

import icon1 from "../imgs/desktop/icon1.png";
import icon2 from "../imgs/desktop/icon2.png";
import { useState, useEffect } from "react";

import axios from "axios";

const Jobs = () => {
  const [jobs, setJobs] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isJobs, setIsJobs] = useState(false);
  const [elements, setElements] = useState([]);
  const [index, setIndex] = useState(null);
  const [jobText, setjobText] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/aktuelles?populate=*"
      )
      .then((res) => {
        setElements(res.data.data);
      });
    axios
      .get(
        "https://cmsstrapi-932913427151.herokuapp.com/api/Jobs?populate=*"
      )
      .then((res) => {
        setjobText(res.data.data[0].attributes.Jobs_Beschreibung);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (window.location.pathname == "/aktuelles/jobs") {
        setIsJobs(false);
      } else if (window.location.pathname == "/jobs") {
        setIsJobs(true);
      }
    }
  }, [isMobile]);

  return (
    <section class="jobs">
      <div class="contentHead">
        <div class="jobsleftBox">Unsere aktuellen Projekte</div>
        {isJobs || !isMobile ? (
          <div class="jobsrightBox">Gestalten bei Vey</div>
        ) : null}
      </div>
      <div class="imgesBox">
        <div
          className="jobsInofBox"
          style={{ width: !isJobs ? "100%" : "52%" }}
        >
          {elements.map((e, i) => {
            if (jobs && index == i) {
              return (
                <>
                  <div class="img1Box">
                    <img
                      className="img1"
                      src={`https://cmsstrapi-932913427151.herokuapp.com${
                        isMobile
                          ? e.attributes.bild_Mobile.data[0].attributes.url
                          : e.attributes.bild_Desktop.data[0].attributes.url
                      }`}
                    />
                    <img
                      className="icon1"
                      src={jobs ? icon2 : icon1}
                      onClick={() => {
                        setIndex(i);
                        setJobs(!jobs);
                      }}
                    />
                  </div>
                  <p className="img1Boxp">{e.attributes.Beschreibung}</p>
                </>
              );
            }

            if (!jobs) {
              return (
                <>
                  <div class="img1Box">
                    <img
                      className="img1"
                      src={`https://cmsstrapi-932913427151.herokuapp.com${
                        isMobile
                          ? e.attributes.bild_Mobile.data[0].attributes.url
                          : e.attributes.bild_Desktop.data[0].attributes.url
                      }`}
                    />
                    <img
                      className="icon1"
                      src={jobs ? icon2 : icon1}
                      onClick={() => {
                        setIndex(i);
                        setJobs(!jobs);
                      }}
                    />
                  </div>
                  <p className="img1Boxp">
                    {
                      isMobile ?
                       e.attributes.Beschreibung
                      :
                       e.attributes.Beschreibung.substring(0, 65) + '..'
                    }
                  </p>
                </>
              );
            }
          })}
        </div>

        {!jobs && !isMobile ? (
          <div className="img2Box">
            <img className="img2" src={isMobile ? img2m : img2} />
            <div className="img2BoxrightBox">
              <span>Herzlichen Dank für Ihr</span> <br />
              <span> Interesse!</span>
              <br />
              <br />
              {jobText}
              <br />
              <br />
              Vey Gestaltung, Sebastian Vey
              <br />
              OT Krekel
              <br />
              Rüther Straße 17
              <br />
              53925 Kall
              <br />
              E-Mail: Info@s-vey.de
              <br />
            </div>
          </div>
        ) : null}

        {isJobs ? (
          <div className="img2Box">
            <img className="img2" src={isMobile ? img2m : img2} />
            <div className="img2BoxrightBox">
              <span>Herzlichen Dank für Ihr</span> <br />
              <span> Interesse!</span>
              <br />
              <br />
              {jobText}
              <br />
              <br />
              Vey Gestaltung, Sebastian Vey
              <br />
              OT Krekel
              <br />
              Rüther Straße 17
              <br />
              53925 Kall
              <br />
              E-Mail: Info@s-vey.de
              <br />
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export { Jobs };
